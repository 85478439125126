import { Company } from '../../redux/api/company/company.model';
import { DispatcherPayRateType } from '../../redux/api/dispatchers/dispatcher.model';
import { DRIVER_TYPE } from '../../redux/api/driver/driver.model';
import { LoadLocation } from '../../redux/api/load/load.model';
import { SavedViewSection } from '../../redux/api/savedViews/savedViews.model';
import { ActiveColumns } from '../Settings/DisplaySettings.model';

export enum USER_ROLE {
    ADMIN = 'ADMIN',
    OWNER = 'OWNER',
    DISPATCHER = 'DISPATCHER',
    DRIVER = 'DRIVER',
    CARRIER = 'CARRIER',
    CUSTOMER_CONTACT = 'CUSTOMER_CONTACT'
}

export enum USER_STATUS {
    ACTIVE = 'ACTIVE',
    INVITED = 'INVITED',
    DISABLED = 'DISABLED',
    NOT_INVITED = 'NOT_INVITED'
}

export enum TENANT_SUBSCRIPTION_STATUS {
    IN_TRIAL = 'in_trial',
    ACTIVE = 'active',
    FUTURE = 'future',
    CANCELLED = 'cancelled',
    NON_RENEWING = 'non_renewing',
    PAUSED = 'paused'
}

export enum InvoiceSubjectLine {
    INVOICE_NUMBER = 'INVOICE_NUMBER',
    CUSTOMER_LOAD_NUMBER = 'CUSTOMER_LOAD_NUMBER',
    INVOICE_NUMBER_AND_CUSTOMER_LOAD = 'INVOICE_NUMBER_AND_CUSTOMER_LOAD'
}

export enum DefaultInvoiceDate {
    INVOICE_CREATION_DATE = 'INVOICE_CREATION_DATE',
    LOAD_PICKUP_DATE = 'LOAD_PICKUP_DATE',
    LOAD_DELIVERY_DATE = 'LOAD_DELIVERY_DATE'
}

/**
 * Timezones response
 */
export interface UserTenant {
    id?: string;
    location: LoadLocation;
    company_name: string;
    division_id: string;
    ltl_enabled: boolean;
}
/**
 * User information to receive in response.
 * */
export interface UserIntegrations {
    quickbooksOnline: boolean;
    eldConnections: boolean;
}

export interface TenantSetting {
    [key: string]: boolean;
}

export interface FactoringCompany {
    id: string;
    name: string;
    address?: string | null;
    email_address?: string | null;
}

export interface InvoiceDescriptionSettings {
    show_pickup_stops: boolean;
    show_drop_off_stops: boolean;
    show_transfer_stops: boolean;
    show_unspecific_stops: boolean;
    show_truckbase_load_number: boolean;
    show_customer_load_number: boolean;
    show_load_dates: boolean;
    show_stop_name: boolean;
    show_stop_address: boolean;
    show_pickup_and_delivery_numbers: boolean;
    show_commodities: boolean;
    show_trucks: boolean;
    show_trailers: boolean;
    show_drivers: boolean;
    show_total_empty_miles: boolean;
    show_total_loaded_miles: boolean;
    show_tags: boolean;
}

export interface DivisionQboCredential {
    id: string;
    division_id: string;
    company_name: string;
}

export interface TenantAccountingSettings {
    division_id: string;
    accounting_email: string;
    cc_accounting_email: boolean;
    cc_user_email: boolean;
    to_cust_billing_email: boolean;
    to_cust_contact_email: boolean;
    invoice_description: InvoiceDescriptionSettings;
    default_invoice_service_id: string;
    default_qbo_invoice_service_id: string;
    invoice_subject_line: InvoiceSubjectLine;
    default_invoice_date: DefaultInvoiceDate;
    qbo_customer_number_custom_field: string;
    division_qbo_credentials: DivisionQboCredential;
    send_invoices_to_factoring_by_default: boolean;
    internal_factoring_company: FactoringCompany;
}

export interface UserSavedViewItem {
    id: string;
    name: string;
}

export interface UserDisplaySettings {
    load_table?: {
        columns: ActiveColumns[];
    };
    trip_table?: {
        columns: ActiveColumns[];
    };
    availability_table?: {
        columns: ActiveColumns[];
    };
    page_size?: number;
    trip_page_size?: number;
    driver_page_size?: number;

    saved_views?: Partial<Record<SavedViewSection, UserSavedViewItem[]>>;
    equipment_page_size?: number;

    edi_page_size?: number;

    availability_page_size?: number;

}

export interface UserResponse {
    id: string;
    first_name: string;
    last_name: string;
    email_address: string;
    company_name: string;
    carrier_name?: string;
    phone: string;
    timezone?: string;
    short_offset?: string;
    offset?: string;
    role: USER_ROLE;
    status: USER_STATUS;
    integrations?: UserIntegrations;
    tenant?: UserTenant;
    tenantSettings?: TenantSetting;
    trial_ends?: string | null;
    tenantSubscriptionStatus?: TENANT_SUBSCRIPTION_STATUS | null;
    dispatcher: {
        id: string;
        pay_rate_type?: string;
        pay_rate?: string;
    };
    driver: {
        id: string;
        pay_rate_type?: string;
        pay_rate?: string;
        default_truck_id?: string;
        default_trailer_id?: string;
        carrier_id?: string;
        type?: string;
    };
    carrier: {
        id: string;
        name: string;
        address: string;
        carrier_name: string;
        default_pay_rate_type?: string;
        pay_rate?: string;
    };
    customer_contact: {
        id: string;
        first_name: string;
        last_name: string;
        email_address: string | null;
        phone: string | null;
        phone_ext: string | null;
        customer_id: string;
        customer: {
            id: string;
            name: string;
            location: Location | null;
        };
    } | null;
    factoringCompany?: FactoringCompany;
    tenantAccountingSettings?: TenantAccountingSettings;
    userDisplaySettings: null | UserDisplaySettings;
    defaultDivision: Company;
    intercom_user_hash: string | null;
}

export interface CreateUserRequest {
    first_name: string;
    last_name: string;
    carrier_name?: string;
    email_address: string;
    dispatcher_id?: string;
    role: USER_ROLE;
    phone?: string;
    send_email_invite?: boolean;
    pay_rate_type?: DispatcherPayRateType | null;
    pay_rate?: string | null;
    default_truck_id?: string;
    default_trailer_id?: string;
    type?: DRIVER_TYPE;
    carrier_id?: string;
}

export interface EditUserRequest {
    id?: string;
    first_name?: string;
    last_name?: string;
    carrier_name?: string;
    email_address?: string;
    role?: USER_ROLE;
    phone?: string;
    status: USER_STATUS;
}

export interface InvitedUserResponse {
    first_name: string;
    last_name: string;
    email_address: string;
    phone: string;
    tenant: {
        id: string;
        company_name: string;
        usdot_number: string | null;
    };
    role?: USER_ROLE;
}
